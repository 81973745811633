var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',_vm._g(_vm._b({directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],style:({
    width: _vm.$attrs.width || '100%',
    'max-height': _vm.$attrs['max-height'] || 'auto',
    'min-height': _vm.$attrs['min-height'] || 'auto',
  }),attrs:{"data":_vm.formatData,"row-style":_vm.showRow,"tooltip-effect":_vm.$attrs['tooltip-effect'] || 'dark',"border":_vm.$attrs.border || true}},'el-table',
    Object.assign(
      _vm.height === 'auto'
        ? {}
        : {
            height: 'calc(100vh - 180px)',
          },
      _vm.$attrs
    )
  ,false),_vm.$listeners),[_c('template',{slot:"empty"},[_vm._t("empty",[_vm._v(" "+_vm._s(_vm.$attrs["empty-text"] || "暂无数据")+" ")])],2),(_vm.selection)?_c('el-table-column',{attrs:{"type":"selection","width":"55"}}):_vm._e(),_vm._l((_vm.columns.slice(0, 1)),function(column,index){return _c('el-table-column',{key:column.prop,attrs:{"label":column.label,"minWidth":column.minWidth},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"display":"flex"}},[_vm._l((scope.row._level),function(space){return (index === 0)?_c('span',{key:space,staticClass:"ms-tree-space"}):_vm._e()}),(_vm.iconShow(index, scope.row))?_c('span',{staticClass:"tree-ctrl",on:{"click":function($event){return _vm.toggleExpanded(scope.$index)}}},[(!scope.row._expanded)?_c('i',{staticClass:"el-icon-plus"}):_c('i',{staticClass:"el-icon-minus"})]):_vm._e(),(!column.slotName)?_c('span',[_vm._v(" "+_vm._s(scope.row[column.prop] == "company" ? "公司" : scope.row[column.prop] == "department" ? "部门" : scope.row[column.prop])+" ")]):_vm._t(column.slotName,null,{"scoped":Object.assign({}, scope.row, {prop: column.prop, $index: scope.$index})})],2)]}}],null,true)})}),_vm._l((_vm.columns.slice(1)),function(ref,index){
    var prop = ref.prop;
    var label = ref.label;
    var width = ref.width;
    var minWidth = ref.minWidth;
    var headerSlotName = ref.headerSlotName;
    var slotName = ref.slotName;
    var isSex = ref.isSex;
    var isPic = ref.isPic;
    var align = ref.align;
    var detailArr = ref.detailArr;
return [(isPic)?_c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":"","prop":prop,"label":label,"width":width,"min-width":minWidth},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"点击可以查看原图","placement":"right"}},[_c('el-image',{staticStyle:{"width":"120px","height":"80px"},attrs:{"src":_vm._f("formatUrl")(row[prop]),"preview-src-list":_vm._f("prvFormatUrl")(row[prop]),"fit":"cover"}})],1)]}}],null,true)}):(detailArr)?_c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":"","prop":prop,"label":label,"width":width,"min-width":minWidth},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('span',[_vm._v(_vm._s(detailArr[row[prop]]))])]}}],null,true)}):(headerSlotName && slotName)?[_c('el-table-column',{key:index,attrs:{"prop":prop,"label":label,"width":width,"align":align,"min-width":minWidth},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var row = ref.row;
return [_vm._t(headerSlotName,null,{"scoped":Object.assign({}, row, {prop: prop})})]}},{key:"default",fn:function(ref){
    var row = ref.row;
return [_vm._t(slotName,null,{"scoped":Object.assign({}, row, {prop: prop})})]}}],null,true)})]:(slotName)?_c('el-table-column',{key:index,attrs:{"prop":prop,"label":label,"width":width,"min-width":minWidth,"align":align},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
    var $index = ref.$index;
return [_vm._t(slotName,null,{"scoped":Object.assign({}, row, {prop: prop, $index: $index})})]}}],null,true)}):(headerSlotName)?_c('el-table-column',{key:index,attrs:{"prop":prop,"label":label,"width":width,"min-width":minWidth},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var row = ref.row;
return [_vm._t(headerSlotName,null,{"scoped":Object.assign({}, row, {prop: prop})})]}}],null,true)}):(isSex)?_c('el-table-column',{key:index,attrs:{"prop":prop,"label":label,"min-width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [(row[prop] == 0)?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("未知")]):(row[prop] == 1)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("男")]):(row[prop] == 2)?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("女")]):_vm._e()]}}],null,true)}):_c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":"","prop":prop,"label":label,"minWidth":minWidth}})]}),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }